<template>
    <div class="mt-3" data-testid="tour-search">
        <div class="row">
            <div class="-query-container col-12 col-lg-3">
                <AutocompleteInput v-model="query" :show-icon="false" :show-mobile-button="false" :type="ProductType.TOURS" placeholder-text="Keyword" @search="doSearch" @select="selectCategory" @clear="() => { query = '' }" />
            </div>
            <div class="col-12 col-lg-3 mt-1 mt-lg-0">
                <label class="select--styled mb-0">
                    <select v-model="destination" name="destination" aria-label="destination">
                        <option value="">Any Destination</option>
                        <option>Africa</option>
                        <option>Antarctica</option>
                        <option>Asia</option>
                        <option>Caribbean</option>
                        <option>Central America</option>
                        <option>Europe</option>
                        <option>Middle East</option>
                        <option>North America</option>
                        <option>South America</option>
                        <option>South Pacific</option>
                    </select>
                </label>
            </div>
            <div class="col-12 col-lg-4 mt-1 mt-lg-0">
                <date-picker-component id="filter-dates" placeholder-text="Travel Dates"></date-picker-component>
            </div>
            <div class="col-12 col-lg-2 mt-0 d-none d-lg-block">
                <button class="btn btn-primary-emphasis btn-sm js-search-button w-100" @click="doSearch">Search</button>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 col-lg-2">
                <button class="btn btn-xs btn-tertiary" @click="toggleMoreFilters"><i :class="(isMoreFiltersOpen) ? 'icon-angle-down-ut' : 'icon-angle-right-ut'"></i> More filters</button>
            </div>
            <div id="more-filters-panel" class="col-12 col-lg-10 text--small">
                <div class="row">
                    <div class="col-12 col-md-4 col-lg-3 col-xl-2">
                        <span class="text-emphasis font-weight-bold">Duration</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in durationFacets" :key="facetIndex">
                                <label>
                                    <input :id="'lengths-' + facet" :key="'lengths-' + facet" v-model="selectedLengths" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-8 col-lg-9">
                        <span class="text-emphasis font-weight-bold">Experiences</span>
                        <ul id="experiences-container" class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in experienceFacets" :key="facetIndex">
                                <label>
                                    <input :id="'experiences-' + facet" :key="'experiences-' + facet" v-model="selectedExperiences" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-1 d-lg-none">
                <button class="btn btn-primary-emphasis btn-sm js-search-button w-100" @click="doSearch">Search</button>
            </div>
        </div>
        <div class="mt-2 text--small fw-bold d-none d-lg-block">
            <a :href="b2bCatalogLink" v-html="legacyLinkText"></a>
        </div>
    </div>
</template>


<script lang="ts">
    import AutocompleteInput from "components/shared/autocomplete-input.vue";
    import DatePickerComponent from "components/shared/date-picker.vue";
    import { facetsByProduct } from "config/search-facet-data";
    import { ProductType } from "interfaces/enums";
    import { BoxLink } from "interfaces/link";
    import { ProductSearchConfig } from "interfaces/product";
    import { isNetworkUser, isSupplier } from "services/auth/user-info";
    import { trackOmniboxSearch } from "services/analytics";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import { defineComponent, PropType } from "vue";

    export default defineComponent({
        components: {
            AutocompleteInput,
            DatePickerComponent
        },

        props: {
            brands: {
                type: Array as PropType<BoxLink[]>,
                default: undefined
            }
        },

        data() {
            return {
                ProductType,
                b2bCatalogLink: virtuosoSharedHeader.cobrandLink("/tours"),
                durationFacets: facetsByProduct[ProductType.TOURS].find((category: ProductSearchConfig) => category.categoryName === "travelLengths")?.facets,
                destination: "",
                experienceFacets: facetsByProduct[ProductType.TOURS].find((category: ProductSearchConfig) => category.categoryName === "experiences")?.facets,
                isMoreFiltersOpen: false,
                isNetworkUser: isNetworkUser(),
                legacyLinkText: `For ${(isSupplier()) ? "Partners" : "Advisors"}: Advanced Search`,
                omniboxCategorySearch: "",
                query: "",
                selectedLengths: [],
                selectedExperiences: []
            };
        },

        methods: {
            doSearch: function (): void {
                let queryHash = "#";

                // Omnibox Search
                if (this.omniboxCategorySearch !== "") {
                    queryHash += `${encodeURIComponent(this.omniboxCategorySearch.trim())}=${encodeURIComponent(this.query.trim())}&`;

                    // trackGAEvent in the selectCategory
                }

                // Text query
                if (this.query.trim() !== "" && this.omniboxCategorySearch === "") {
                    queryHash += `query=${encodeURIComponent(this.query.trim())}&sort=SearchRelevance&`;
                }

                // Destination
                if (this.destination !== "") {
                    queryHash += `destinations=${encodeURIComponent(this.destination)}&`;
                }

                // Travel dates (they'll either both be populated or neither)
                const startDate = $("#dp-start-date").val().toString();
                const endDate = $("#dp-end-date").val().toString();

                if (startDate !== "" && endDate !== "") {
                    queryHash += `startDate=${startDate}&endDate=${endDate}&`;
                }

                // Tour length
                if (this.selectedLengths.length > 0) {
                    queryHash += `travelLengths=${this.selectedLengths.join("|")}&`;
                }

                // Experiences
                if (this.selectedExperiences.length > 0) {
                    queryHash += `experiences=${this.selectedExperiences.join("|")}&`;
                }
                if (queryHash.slice(-1) === "&") {
                    queryHash = queryHash.slice(0, -1); // Trim trailing ampersand
                }

                window.location.href = virtuosoSharedHeader.cobrandLink(`/travel/luxury-tours/search${queryHash}`);
            },

            selectCategory: function (acCategory: string, acValue: string): void {
                this.query = acValue;

                switch (acCategory) {
                    case "city":
                        this.omniboxCategorySearch = "citySearch";
                        break;
                    case "country":
                        this.omniboxCategorySearch = "countrySearch";
                        break;
                    case "company name":
                        this.omniboxCategorySearch = "companySearch";
                        break;
                    case "all fields":
                        this.omniboxCategorySearch = "";
                        break;
                }

                trackOmniboxSearch(ProductType.TOURS, acCategory, acValue);

                this.doSearch("Omnibox selection");
            },

            toggleMoreFilters: function (): void {
                if (this.isMoreFiltersOpen) {
                    $("#more-filters-panel").slideUp();
                } else {
                    $("#more-filters-panel").slideDown();
                }

                this.isMoreFiltersOpen = !this.isMoreFiltersOpen;
            }
        }
    });
</script>
