<template>
    <div class="mt-3" data-testid="hotel-search">
        <div class="row">
            <div class="col-12 col-md-9 col-lg-6 mb-3 mb-md-0">                
                <AutocompleteInput v-model="query" :show-icon="false" :show-mobile-button="false" :type="ProductType.HOTELS" placeholder-text="Where do you want to go?" @search="doSearch" @select="selectCategory" @clear="() => { query = '' }" />
            </div>
            <div class="col-12 col-md-3 col-lg-2">
                <button class="btn btn-primary-emphasis btn-sm w-100" @click="doSearch()">Search</button>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 col-lg-2">
                <button class="btn btn-xs btn-tertiary" @click="toggleMoreFilters"><i :class="(isMoreFiltersOpen) ? 'icon-angle-down-ut' : 'icon-angle-right-ut'"></i> More filters</button>
            </div>
            <div id="more-filters-panel" class="col-12 col-lg-10 text--small">
                <div class="row">
                    <div class="col-12 col-md-4 col-lg-3 ">
                        <span class="text-emphasis font-weight-bold">Hotel Type</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in hotelTypeFacets" :key="facetIndex">
                                <label>
                                    <input :id="'hotelTypes-' + facet" :key="'hotelTypes-' + facet" v-model="selectedHotelTypes" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-8 col-lg-6">
                        <span class="text-emphasis font-weight-bold">Experiences</span>
                        <ul id="experiences-container" class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in experienceFacets" :key="facetIndex">
                                <label>
                                    <input :id="'experiences-' + facet" :key="'experiences-' + facet" v-model="selectedExperiences" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <span class="text-emphasis font-weight-bold">Virtuoso Exclusives</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in exclusivesFacets" :key="facetIndex">
                                <label>
                                    <input :id="'exclusives-' + facet" :key="'exclusives-' + facet" v-model="selectedExclusives" type="checkbox" :value="encodeURIComponent(facet)"> {{ facet }}
                                </label>
                            </li>
                        </ul>

                        <span class="text-emphasis font-weight-bold mt-lg-1">Virtuoso Sustainability</span>
                        <ul class="list-unstyled mt-1">
                            <li v-for="(facet, facetIndex) in sustainabilityFacets" :key="facetIndex">
                                <label>
                                    <input :id="'sustainability-' + facet" :key="'sustainability-' + facet" v-model="selectedSustainability" type="checkbox" :value="true"> {{ facet }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-1 d-lg-none">
                        <button class="btn btn-primary-emphasis btn-sm js-search-button w-100" @click="doSearch()">Search</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 text--small fw-bold d-none d-lg-block">
            <a :href="b2bCatalogLink">Find rates and availability here.</a>
        </div>
    </div>
</template>


<script lang="ts">
    import AutocompleteInput from "components/shared/autocomplete-input.vue";
    import { facetsByProduct } from "config/search-facet-data";
    import { ProductType } from "interfaces/enums";
    import { ProductSearchConfig } from "interfaces/product";
    import { trackOmniboxSearch } from "services/analytics";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import { defineComponent } from "vue";

    const catalogType: ProductType = ProductType.HOTELS;

    export default defineComponent({
        components: { AutocompleteInput },

        data() {
            return {
                ProductType,
                b2bCatalogLink: virtuosoSharedHeader.cobrandLink("/hotels"),
                exclusivesFacets: facetsByProduct[catalogType].find((category: ProductSearchConfig) => category.categoryName === "virtuosoExclusives")?.facets,
                sustainabilityFacets: facetsByProduct[catalogType].find((category: ProductSearchConfig) => category.categoryName === "sustainability")?.facets,
                experienceFacets: facetsByProduct[catalogType].find((category: ProductSearchConfig) => category.categoryName === "experiences")?.facets,
                hotelTypeFacets: facetsByProduct[catalogType].find((category: ProductSearchConfig) => category.categoryName === "hotelTypes")?.facets,
                isMoreFiltersOpen: false,
                omniboxCategorySearch: "",
                query: "",
                selectedExclusives: [],
                selectedExperiences: [],
                selectedHotelTypes: [],
                selectedSustainability: null
            };
        },

        methods: {
            doSearch: function (): void {
                let queryHash = "#";

                // Omnibox Search
                if (this.omniboxCategorySearch !== "") {
                    queryHash += `${encodeURIComponent(this.omniboxCategorySearch.trim())}=${encodeURIComponent(this.query.trim())}&`;

                // trackGAEvent in the selectCategory
                }

                // Text query
                if (this.query.trim() !== "" && this.omniboxCategorySearch === "") {
                    queryHash += `query=${encodeURIComponent(this.query.trim())}&sort=SearchRelevance&`;
                }
                // Virtuoso Exclusives
                if (this.selectedExclusives.length > 0) {
                    queryHash += `virtuosoExclusives=${this.selectedExclusives.join("|")}&`;
                }
                // Virtuoso Sustainability
                if (this.selectedSustainability) {
                    queryHash += "sustainability=true&";
                }
                // Experiences
                if (this.selectedExperiences.length > 0) {
                    queryHash += `experiences=${this.selectedExperiences.join("|")}&`;
                }
                // Hotel Type
                if (this.selectedHotelTypes.length > 0) {
                    queryHash += `hotelTypes=${this.selectedHotelTypes.join("|")}&`;
                }

                window.location.href = virtuosoSharedHeader.cobrandLink(`/travel/luxury-hotels/search${queryHash}`);
                
            },
            selectCategory: function (acCategory: string, acValue: string): void {
                this.query = acValue;

                switch (acCategory) {
                    case "city":
                        this.omniboxCategorySearch = "citySearch";
                        break;
                    case "country":
                        this.omniboxCategorySearch = "countrySearch";
                        break;
                    case "hotel name":
                        this.omniboxCategorySearch = "companySearch";
                        break;
                    case "all fields":
                        this.omniboxCategorySearch = "";
                        break;
                }

                trackOmniboxSearch(ProductType.HOTELS, acCategory, acValue);

                this.doSearch("Omnibox selection");
            },

            toggleMoreFilters: function (): void {
                if (this.isMoreFiltersOpen) {
                    $("#more-filters-panel").slideUp();
                } else {
                    $("#more-filters-panel").slideDown();
                }

                this.isMoreFiltersOpen = !this.isMoreFiltersOpen;
            }
        }
    });
</script>
